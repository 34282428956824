import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    location: 'nema',
    savedList: {},
    savedElems: {},
    isMobile: false
  },
  mutations: {
    initialiseStore (state) {
      if (localStorage.getItem('loggedIn')) {
        state.user.loggedIn = JSON.parse(localStorage.getItem('loggedIn'))
      }
      if (localStorage.getItem('klID')) {
        state.klID = JSON.parse(localStorage.getItem('klID'))
      }
    },
    saveList (state, payload) {
      state.savedList[state.location] = payload;
    },
    saveLocation (state, payload) {
      state.location = payload
    },   
    saveElems (state, payload) {
      state.savedElems[state.location] = payload;
    }, 
    isMobile (state, payload) {
      state.isMobile = payload
    },           
  },
  actions: {
    saveList (state, payload) {
      state.commit('saveList', payload)
    },
    saveLocation (state, payload) {
      state.commit('saveLocation', payload)
    },    
    saveElems (state, payload) {
      state.commit('saveElems', payload)
    },   
    isMobile (state, payload) {
      state.commit('isMobile', payload)
    },          
  },
  getters: {
    sList: state => state.savedList,
    sElems: state => state.savedElems,
    isMobile: state => state.isMobile
  },
  modules: {
  }
})
