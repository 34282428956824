import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "chunk-vendor.23532" */ '../views/Home.vue')
  },
  {
    path: '/namjestaj',
    name: 'Namjestaj',
    component: () => import(/* webpackChunkName: "chunk-vendor.23532" */ '../views/Namjestaj.vue'),
    props: true,
    children: [
      {
        path: '',
        redirect: 'dnevne-garniture-koza'
      },
      {
        path: ':kat',
        component: () => import(/* webpackChunkName: "chunk-vendor.2734322" */ '../views/Ponuda.vue'),
        props: true
      }, 
      {
        path: ':kat/:id*',
        name: 'Item',
        component: () => import(/* webpackChunkName: "chunk-vendor.2734322" */ '../views/Item.vue'),
        props: true
      },                         
    ]
  },   
  {
    path: '/novo',
    name: 'Novo',
    component: () => import(/* webpackChunkName: "chunk-vendor.23532" */ '../views/Namjestaj.vue'),
    props: true,
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "chunk-vendor.2734322" */ '../views/PonudaNovo.vue'),
      },
      {
        path: ':kat/:id*',
        name: 'Item',
        component: () => import(/* webpackChunkName: "chunk-vendor.2734322" */ '../views/Item.vue'),
        props: true
      },                         
    ]
  },   
  {
    path: '/akcije',
    name: 'Akcije',
    component: () => import(/* webpackChunkName: "chunk-vendor.23532" */ '../views/Namjestaj.vue'),
    props: true,
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "chunk-vendor.2734322" */ '../views/PonudaAkcija.vue'),
      },
      {
        path: ':kat/:id*',
        name: 'Item',
        component: () => import(/* webpackChunkName: "chunk-vendor.2734322" */ '../views/Item.vue'),
        props: true
      },                         
    ]
  },      
  {
    path: '/rasprodaje',
    name: 'NG Rasprodaja',
    component: () => import(/* webpackChunkName: "chunk-vendor.23532" */ '../views/Namjestaj.vue'),
    props: true,
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "chunk-vendor.2734322" */ '../views/PonudaRasprodaja.vue'),
      },
      {
        path: ':kat/:id*',
        name: 'Item',
        component: () => import(/* webpackChunkName: "chunk-vendor.2734322" */ '../views/Item.vue'),
        props: true
      },                         
    ]
  },     
  {
    path: '/katalozi',
    name: 'Katalozi',
    component: () => import(/* webpackChunkName: "chunk-vendor.23532" */ '../views/Katalozi.vue')
  },  
  {
    path: '/servis-za-kupce',
    name: 'Servis-za-kupce',
    component: () => import(/* webpackChunkName: "chunk-vendor.23532" */ '../views/ServisZaKupce.vue')
  },    
  {
    path: '/o-nama',
    name: 'O-nama',
    component: () => import(/* webpackChunkName: "chunk-vendor.23532" */ '../views/ONama.vue')
  }, 
  {
    path: '/partneri',
    name: 'Partneri',
    component: () => import(/* webpackChunkName: "chunk-vendor.23532" */ '../views/Partneri.vue')
  },    
  {
    path: '/kontakt',
    name: 'Kontakt',
    component: () => import(/* webpackChunkName: "chunk-vendor.23532" */ '../views/Kontakt.vue')
  },
  {
    path: '/aktuelni-katalog-namjestaja',
    name: 'Katalog',
    component: () => import(/* webpackChunkName: "chunk-vendor.23532" */ '../views/AktuelniKatalog.vue')
  },

  {
    path :'*',
    redirect: '/' 
  }  
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
