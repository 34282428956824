<template>
  <div id="app">
    
    <!--
    
    <let-it-snow v-bind="snowConf" :show="show"></let-it-snow>
    
    <div v-if="floater" @click="floater = false" id="floaterWrapper">
      <div id="floaterHolder">
        <div id="floaterX">X</div>
        <router-link to="/aktuelni-katalog-namjestaja"
          ><img src="/images/floaterng.jpg"
        /></router-link>
      </div>
    </div>
    -->
    <div id="fixTel">
      <a href="tel:+38267666150">porucite na 067 666 150</a>
    </div>
    <div id="header" v-bind:class="{ headerHome: scrly }">
      <div id="brojtel">
        Poručite namještaj na <strong>+382 67 666 150</strong>
      </div>
      <router-link to="/" exact>
        <img id="logo" src="./assets/logo.png" />
      </router-link>
      <div id="searchInputWrapp" style="display: flex; flex-direction: row">
        <input
          type="text"
          placeholder="Pretraga..."
          autocomplete="off"
          :value="searchInput"
          id="searchBox"
          ref="searchBox"
          @input="(evt) => (searchInput = evt.target.value)"
          @focus="showSearch = true"
        />
        <button class="srchBtn" @click="showSearch = !showSearch">
          <img
            v-if="!showSearch"
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADQAAAA0CAYAAADFeBvrAAAABmJLR0QA/wD/AP+gvaeTAAAE7UlEQVRogc2aS2wVVRzGv3OxhkAFNBZL0pJgm4iRIhIg+MC00ZVGXagr1ESxFQNWI2J0gYgrWZiKidGFbsGIbHzEnYZEjYkEBQGBRFloKCb4oFSe7f25OHPt3H/n3jtnZtrrlzRzZ86Z7/v+Z86cx3/qlANAu6SVkm6QdJ2kOVHRiKTfJR2VtM85dyqPzpQC6Aa2AYdJj0PRPV3N9v8fgGXAHmA8IBCLceAjYGkzA2kF3gLGcgRiMQbsAGZPdzA9wNEaps4AO4EngZVAG9AS/bVF1/qBXcBIDY4jwJLpCqYvMm1xDHgCmBXANQtYBxyv0TB9UxlLJZjzRvgcsAm4IgdvC7A5gfs80FtkDHHRnoQnc7zIrgGsBk4mPKmbitKoCLUy+Z3ZD7QVKuS1OoADRusIRQ4U+NHMPpnCg4npdQDDRnOoKPJlVA/N54rsZnV0VwMXYrqXgZ4iiPeYltpUgN+02q8Y7d15CbupXgEcI8dolkG/1XS9cTIuk0rR8bHYb0na7pwby+00JZxzo5JeM74eyUxI9ULzDAGTZlEAZgNnYz5+zErUDpRjRDsT6tyf23E6Lx/EfJSB+aEcJfn9jItd+8KIzJD0Rj6rqRHXdvLeglCS35zFccCc90rqBq4OJc8Aq229NURJ0gJz7YQ5vzM6Xh9KngFW23priJKkVnNtxJzfGB3vCSXPgDPmfE5irTooNa6iedHxgVDyAuAaV6lGSdKouWZbpdJqy4FVWVwFYK45PxtKUJJ00lyz70ql3EnaHioQCKv9WyhBSdIxc+1mc/5N7Hcv8HCoSACs9tFghoSJdZcpnwtcjJWPFLIaTvbyYUznEnBVVqLDxvAsU/4u1fgFWFhIFBMadunzVR6ybcbwOlO+wIgBnAJuzx3JhEa/4d+Sh6yL6u3DcaDF1HnQdE3wG7NXATuXhepfCfxseNvzcAqf0Yxjc0Kd50jOnJ4Cngc6M2q/ZPjeyxVMRLqU6i34eWB1Qr37gD8SgiJ6gvvwWdEXgUeBh6izDgRuo3oLfhFYnDugiPxNY/Ak0JFQbx7wOnC6RmBEjfNJUqPEeDqZnCTZmieGqqUFPoX0nSbWb5J0UNK9zrlJkxx+a7FG0nJJnZJmSjot6ZCkvfU+o+C752eS4lPAD5JWOecuZ4qmhtASJicah+u1dAaNWxOeDMDHTEUug+RU8AV8diZzIhA/mr1M9TtjsRtYAbwArCehy+cJKilZPww8HRIYftIcoHpoTotRYG1arbrLc3yuebeq36kKRuXfgS/l+/4JSX9HZfMkLZJ0i6Q++b1U0lz1bcRzdwOfZUkDzrn3G9RrjKh1h/AZzaJwDt+lZgAzgc9T3DMO9OcOKBZYD75/5/kk+Q/wNrDIcN+V8v4yMFBYUJF4F7AVOMjkpVASLgF78auBa2twbglolDKwvpa/4C2uMTJf0gpJiyW1a2LHOSrpV0k/Sfo6yozW4xmUtCNEWtIG59w7waanA8DCqDuGoAxsaLb3mgDWEv5+loGNzfZeE8DjGYN6ttneawK/0Usz2NigBpvtvSbwq4rQoMaBNc32XhP4f+gI7X7/z1GvAvyaMeRJfZomFdw0RPPMM/LzThp8P4V2igOwMcWTGgauabbX1ACeqhPUX0Dwx7GmAxhk8kAxDCyv1Mm1lmsGgDvkv5B3yL8zQ865Pyvl/wJXi+FKAnw/3wAAAABJRU5ErkJggg=="
          />
          <span v-else>X</span>
        </button>
      </div>
      <burger-button
        v-if="isMobile"
        :active="isActive"
        @click="toggleActive"
        :bar-height="5"
        :bar-width="30"
        :bar-color="isActive ? '#5a5b5c' : '#5a5b5c'"
      />
      <div id="nav" v-if="!isMobile">
        <router-link to="/namjestaj/">
          <div
            @click="ponudaClick($event)"
            @mouseenter="showMenuOdma"
            @mouseleave="hideMenu"
            v-bind:class="{ pomocni: menuShow }"
          >
            ASORTIMAN
          </div>
        </router-link>
        <router-link v-on:click.native="showSearch = false" to="/novo"
          ><div>NOVO</div></router-link
        >
        <router-link v-on:click.native="showSearch = false" to="/akcije"
          ><div>AKCIJE</div></router-link
        >
        <router-link v-on:click.native="showSearch = false" to="/rasprodaje"
          ><div>RASPRODAJA</div></router-link
        >
        <router-link v-on:click.native="showSearch = false" to="/katalozi"
          ><div>KATALOZI</div></router-link
        >
        <router-link
          v-on:click.native="showSearch = false"
          to="/servis-za-kupce"
          ><div>SERVIS ZA KUPCE</div></router-link
        >
        <router-link v-on:click.native="showSearch = false" to="/o-nama"
          ><div>O NAMA</div></router-link
        >
        <!--
        <router-link v-on:click.native="showSearch = false" to="/partneri"
          ><div>PARTNERI</div></router-link
        >
        -->
        <router-link v-on:click.native="showSearch = false" to="/kontakt"
          ><div>KONTAKT</div></router-link
        >
      </div>
      <div class="mobMenu" v-if="isMobile && isActive">
        <div id="menuHolder">
          <router-link
            v-on:click.native="toggleActive()"
            v-for="item in kategorije"
            :key="item.a"
            :to="'/namjestaj/' + item.b"
          >
            <div class="menuItem" @click="hideMenu">
              <img :src="'/images/menu_icons/' + item.b + '.png'" />
              <div class="menuItemText">{{ item.a }}</div>
            </div>
          </router-link>
        </div>
        <router-link v-on:click.native="toggleActive()" to="/novo"
          ><div>NOVO</div></router-link
        >
        <router-link v-on:click.native="toggleActive()" to="/akcije"
          ><div>AKCIJE</div></router-link
        >
        <router-link v-on:click.native="toggleActive()" to="/rasprodaje"
          ><div>RASPRODAJA</div></router-link
        >
        <router-link v-on:click.native="toggleActive()" to="/katalozi"
          ><div>KATALOZI</div></router-link
        >
        <router-link v-on:click.native="toggleActive()" to="/servis-za-kupce"
          ><div>SERVIS ZA KUPCE</div></router-link
        >
        <router-link v-on:click.native="toggleActive()" to="/o-nama"
          ><div>O NAMA</div></router-link
        >
        <!--
        <router-link v-on:click.native="toggleActive()" to="/partneri"
          ><div>PARTNERI</div></router-link
        >
        -->
        <router-link v-on:click.native="toggleActive()" to="/kontakt"
          ><div>KONTAKT</div></router-link
        >
      </div>
      <transition name="fade">
        <div
          id="menuWrapper"
          v-if="menuShow"
          @mouseenter="showMenu()"
          @mouseleave="hideMenu()"
        >
          <div id="menuHolder">
            <router-link
              v-on:click.native="showSearch = false"
              v-for="item in kategorije"
              :key="item.a"
              :to="'/namjestaj/' + item.b"
            >
              <div class="menuItem" @click="hideMenu">
                <img :src="'/images/menu_icons/' + item.b + '.png'" />
                <div class="menuItemText">{{ item.a }}</div>
              </div>
            </router-link>
          </div>
        </div>
        <div id="searchResultWrapper" v-if="showSearch && searchInput !== ''">
          <div style="width: 100%; position: relative; text-align: center">
            REZULTAT PRETRAGE: {{ filteredList.length }} artikala
          </div>
          <div
            class="item"
            v-for="(item, index) in filteredList"
            :key="index"
            v-bind:class="'koko5'"
          >
            <router-link
              v-on:click.native="showSearch = false"
              :to="
                '/namjestaj/' +
                item.kat_url +
                '/' +
                item.art_id +
                '/' +
                item.pod_url +
                '-' +
                item.art_naziv.toLowerCase().replace(' ', '-')
              "
            >
              <img
                :src="'/images/namjestaj/' + item.art_id + '/0.jpg'"
                :alt="item.pod_naziv + item.art_naziv"
              />
              <div v-if="item.art_sastav == 1">
                <div class="naziv">
                  {{ item.pod_naziv }} <span>{{ item.art_naziv }}</span>
                  {{ item.art_boja }}
                </div>
                <div class="popCijena">
                  <div v-if="item.art_gornja != 0">
                    {{ item.art_gornja }}<span>€</span>
                  </div>
                  <div v-if="item.art_gornja2 != 0">
                    {{ item.art_gornja2 }}<span>€</span>
                  </div>
                  <div v-if="item.art_srednja != 0">
                    {{ item.art_srednja }}<span>€</span>
                  </div>
                </div>
                <div v-if="item.art_donja != 0" class="cijena">
                  {{ item.art_donja }}<span>€</span>
                </div>
                <div class="popustWrapper">
                  <div class="popust popdod" v-if="item.art_gornja != 0">
                    <div>
                      {{ calcPopustB(item.art_donja, item.art_srednja)
                      }}<span>%</span>
                    </div>
                  </div>
                  <div class="popust popdod2" v-if="item.art_gornja2 != 0">
                    <div>
                      {{ calcPopustB(item.art_donja, item.art_srednja)
                      }}<span>%</span>
                    </div>
                  </div>
                  <div
                    class="popust"
                    v-bind:class="
                      item.art_rasprodaja == 1
                        ? 'popeksponat'
                        : item.art_rasprodaja == 2
                        ? 'popfinalna'
                        : 'popsniz2'
                    "
                    v-if="item.art_srednja != 0"
                  >
                    <div>
                    <!--
                      {{
                        calcPopustA(
                          item.art_donja,
                          item.art_srednja,
                          item.art_gornja,
                          item.art_gornja2
                        )
                      }}
                      -->
                      30
                      <span>%</span>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else-if="item.art_sastav == 2">
                <div v-if="elemLoaded">
                  <div class="naziv" v-if="item.art_naziv == ''">
                    <span v-html="sastavNaziv(item.art_artikli)"></span>
                    <span v-if="item.art_boja">{{ item.art_boja }}</span>
                  </div>
                  <div class="naziv" v-else>
                    {{ item.pod_naziv }} <span>{{ item.art_naziv }}</span>
                    {{ item.art_boja }}
                  </div>
                  <div class="popCijena">
                    <div v-if="item.art_gornja != 0">
                      {{ item.art_gornja }}<span>€</span>
                    </div>
                    <div v-if="item.art_gornja2 != 0">
                      {{ item.art_gornja2 }}<span>€</span>
                    </div>
                    <div v-if="item.art_srednja != 0">
                      {{ item.art_srednja }}<span>€</span>
                    </div>
                  </div>
                  <div v-if="item.art_donja != 0" class="cijena">
                    {{ item.art_donja }}<span>€</span>
                  </div>
                </div>
                <div class="popustWrapper">
                  <div class="popust popdod" v-if="item.art_gornja != 0">
                    <div>
                      {{ calcPopustB(item.art_donja, item.art_srednja)
                      }}<span>%</span>
                    </div>
                  </div>
                  <div class="popust popdod2" v-if="item.art_gornja2 != 0">
                    <div>
                      {{ calcPopustB(item.art_donja, item.art_srednja)
                      }}<span>%</span>
                    </div>
                  </div>
                  <div class="popust popsniz2" v-if="item.art_srednja != 0">
                    <div>
                    <!--
                      {{
                        calcPopustA(
                          item.art_donja,
                          item.art_srednja,
                          item.art_gornja,
                          item.art_gornja2
                        )
                      }}
                      -->
                      30
                      <span>%</span>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else-if="item.art_sastav == 3">
                <div v-if="elemLoaded">
                  <div class="naziv">
                    {{ item.pod_naziv }} <span>{{ item.art_naziv }}</span>
                    {{ item.art_boja }}
                  </div>
                  <div class="popCijena">
                    <div v-if="item.art_gornja != 0">
                      {{ item.art_gornja }}<span>€</span>
                    </div>
                    <div v-if="item.art_gornja2 != 0">
                      {{ item.art_gornja2 }}<span>€</span>
                    </div>
                    <div v-if="item.art_srednja != 0">
                      {{ item.art_srednja }}<span>€</span>
                    </div>
                  </div>
                  <div v-if="item.art_donja != 0" class="cijena">
                    {{ item.art_donja }}<span>€</span>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </transition>
    </div>
    <div id="pageHolder" v-bind:class="{ phHome: scrlp }">
      <router-view
        v-on:click.native="showSearch = false"
        :key="$route.path"
      ></router-view>
    </div>
    <div id="footerHolder">
      <div id="footerTre">
        <img src="/images/footer/1.jpg" />
        <img src="/images/footer/2.jpg" />
        <img src="/images/footer/3.jpg" />
      </div>
      <div id="fTop">
        <div id="onamaWrapper">
          <div id="onamaNaslov">Dajković Comapny Namos d.o.o.</div>
          <div>
            Adresa: Cetinjski put bb, Donja gorica<br />
            81000 Podgorica, Montenegro<br /><br />
            Info mob: 067 666 150 i 067 666 915
          </div>
          
          
          <!--
          <div id="nagradeWrapper">
            <div class="nagrada">
              <img src="/images/nagrade/qudal-quality-medal.png" />
            </div>
            <div class="nagrada">
              <img src="/images/nagrade/certificate-of-excellence.png" />
            </div>
            <div class="nagrada">
              <img src="/images/nagrade/best-buy-award.png" />
            </div>
          </div>
          -->
          
          <div id="nagradeWrapper">
            <div class="nagrada">
              <img src="/images/nagrade/1.png" />
            </div>
            <div class="nagrada">
              <img src="/images/nagrade/2.png" />
            </div>
          </div>
          
          
          
        </div>
        <div class="linkoviWrapper">
          <div class="linkoviNaslov">Asortiman</div>
          <div class="linkoviSpisak">
            <router-link
              v-for="item in kategorije"
              :key="item.a"
              :to="'/namjestaj/' + item.b"
            >
              <div class="" @click="hideMenu">
                {{ item.a }}
              </div>
            </router-link>
          </div>
        </div>
        <div class="servisWrapper">
          <div class="linkoviNaslov">Servis za kupce</div>
          <div class="linkoviSpisak">
            <router-link to="/servis-za-kupce/">
              <div @click="hideMenu">Besplatan prevoz i montaža</div>
            </router-link>
            <router-link to="/servis-za-kupce/">
              <div @click="hideMenu">Način plaćanja</div>
            </router-link>
            <router-link to="/servis-za-kupce/">
              <div @click="hideMenu">Pravilnik kompanije</div>
            </router-link>
            <router-link to="/servis-za-kupce/">
              <div @click="hideMenu">FAQ - Česta pitanja</div>
            </router-link>
          </div>
        </div>
        <div id="rvWrapper">
          Radno vrijeme salona:<br />
          <small>PONEDELJAK - SUBOTA</small><br />
          od 08:00 do 21:00
        </div>
        <div id="katalogWrapper">
          <router-link to="/aktuelni-katalog-namjestaja">
            <img src="/images/katalog/0.jpg" />
          </router-link>
          <div id="katalogNatpis">PRELISTAJTE AKTUELNI KATALOG</div>
        </div>
      </div>
      <div id="fBottom">
        <div class="felem">
          <a href="https://www.facebook.com/namos.dajkovic/" target="_blank">
            <img src="/img/fb.png" />
          </a>
          <a
            href="https://instagram.com/namosdajkovic_since1994?utm_medium=copy_link"
            target="_blank"
          >
            <img src="/img/in.png" />
          </a>
          <!--
          <router-link to="www.facebook.com">
            <img src="/img/yt.png" />
          </router-link>
          -->
        </div>
        <div class="felem"></div>
        <div class="felem">
          ©{{ new Date().getFullYear() }} Dajković Co. Namos d.o.o. - Sva prava
          zadržana.
        </div>
        <div class="felem">
          <strong>{{ brojac }}</strong>
        </div>
        <div class="felem"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      /*
      snowConf: {
        windPower: 1,
        speed: 1,
        count: 12,
        size: 10,
        opacity: 1,
        images: [
          "https://raw.githubusercontent.com/bob-chen/let_it_snow/master/demo/snow.png"
        ]
      },
      */
      kategorije: [
        { a: "Dnevne garniture koža", b: "dnevne-garniture-koza" },
        {
          a: "Dnevne garniture eko-koža / štof",
          b: "dnevne-garniture-eko-koza-stof"
        },
        { a: "Kreveti", b: "kreveti" },
        { a: "Kuhinje", b: "kuhinje" },
        { a: "Trpezarije", b: "trpezarije" },
        { a: "Ležajevi", b: "lezajevi" },
        { a: "Dječije sobe", b: "djecije-sobe" },
        { a: "Spavaće sobe", b: "spavace-sobe" },
        { a: "Dušeci", b: "duseci" },
        { a: "Plakari", b: "plakari" },
        { a: "Predsoblja", b: "predsoblja" },
        { a: "Kancelarijski namještaj", b: "kancelarijski-namjestaj" },
        { a: "TV vitrine i regali", b: "tv-vitrine-i-reglai" },
        { a: "Klub stolovi", b: "klub-stolovi" },
        { a: "Ogledala", b: "ogledala" },
        { a: "Baštenski program", b: "bastenski-program" }
      ],
      menuShow: false,
      menuCheck: true,
      timer: "",
      scrly: false,
      scrlp: true,
      pndList: [],
      searchList: [],
      isActive: false,
      searchInput: "",
      elemList: {},
      elemLoaded: false,
      showSearch: false,
      jelte: false,
      floater: false,
      brojac: 0,
      audio: false
    };
  },
  watch: {
    $route(to) {
      if (to.path != "/") {
        this.scrly = false;
        this.scrlp = true;
      } else {
        this.scrly = window.scrollY < 20;
        this.scrlp = false;
      }
    }
  },
  methods: {
    /*
    async loadItems() {
      const response = await fetch("/kaodbs.php");
      const data = await response.json();
      this.pndList = data;
    },
    */
    calcPopustA(donja, srednja, gornja, gornja2) {
      var a = 0.0;
      if (gornja != 0) {
        a = ((gornja - srednja) / gornja) * 100;
        if (a - Math.floor(a) >= 0.9) return Math.ceil(a);
        return Math.floor(a);
      } else if (gornja2 != 0) {
        a = ((gornja2 - srednja) / gornja2) * 100;
        if (a - Math.floor(a) >= 0.9) return Math.ceil(a);
        return Math.floor(a);
      } else {
        a = ((srednja - donja) / srednja) * 100;
        if (a - Math.floor(a) >= 0.9) return Math.ceil(a);
        return Math.floor(a);
      }
    },
    calcPopustB(donja, srednja) {
      var a = ((srednja - donja) / srednja) * 100;
      if (a - Math.floor(a) >= 0.9) return Math.ceil(a);
      return Math.floor(a);
    },
    sastavNaziv(item) {
      var a = "";
      if (item.length > 0)
        JSON.parse(item).map(val => {
          a =
            a +
            (val.kolicina > 1 ? val.kolicina + "x " : "") +
            this.elemList[val.sifra].pod_naziv +
            " <span>" +
            this.elemList[val.sifra].art_naziv +
            "</span> + ";
        });
      return a.substring(0, a.length - 3);
    },
    toggleActive() {
      this.isActive = !this.isActive;
      this.showSearch = false;
    },
    async loadAll() {
      const response = await fetch("/kaoitemlist.php");
      const data = await response.json();
      this.searchList = data;
      this.searchList.forEach(element => {
        this.elemList[element.art_id] = element;
        const nzv = element.art_naziv
          ? "," + element.art_naziv.replace(" ", ",")
          : "";
        const konkat = element.kat_tagovi + "," + element.pod_tagovi + nzv;
        element.tagovi = konkat.toLowerCase();
      });
      this.elemLoaded = true;
    },
    async loadBrojac() {
      const response = await fetch("/brojac.php");
      const data = await response.json();
      let temp = data;
      temp.forEach(element => {
        this.brojac = element.val;
      });
    },
    hideMenu() {
      this.menuCheck = false;
      this.timer = setTimeout(() => {
        this.menuShow = false;
      }, 20);
    },
    showMenu() {
      clearTimeout(this.timer);
      if (this.menuChekc) this.menuShow = true;
    },
    showMenuOdma() {
      clearTimeout(this.timer);
      this.menuShow = true;
    },
    ponudaClick(event) {
      event.preventDefault();
    },
    checkScroll() {
      if (this.$route.path == "/") {
        this.scrly = window.scrollY < 20;
      } else {
        this.scrly = false;
      }
    },
    
    playAudio() {
      if (!this.audio) {
        new Audio("/jingle.mp3").play();
        this.audio = true;
      }
     // window.removeEventListener("click", this.playAudio);
    },
    
    onResize() {
      if (window.innerWidth <= 600) {
        this.$store.dispatch("isMobile", true);
      } else {
        this.$store.dispatch("isMobile", false);
      }
    }
  },
  created() {
    window.addEventListener("scroll", this.checkScroll, false);
    //window.addEventListener("click", this.playAudio);
    //this.loadItems();
    this.loadAll();
    window.addEventListener("resize", this.onResize);
    this.onResize();
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  mounted() {
    this.show = false;
    //   setTimeout( () => {
    //       this.show = false
    //   }, 5000)

    //   setTimeout( () => {
    //       this.show = true
    //   }, 10000)
    setTimeout(() => {
      if (this.$route.path == "/") this.floater = true;
      this.loadBrojac();
    }, 500);
  },
  computed: {
    isMobile: function() {
      return this.$store.getters.isMobile;
    },
    filteredList() {
      if (this.searchInput !== "") {
        var nizs = this.searchInput.toLowerCase().split(" ");
        return this.searchList.filter(post => {
          return nizs.every(
            v => post.tagovi.includes(v) && post.art_brslika > 0
          );
        });
      } else {
        return [];
      }
    }
  }
};
</script>   

<style lang="scss">
/* Hide scrollbar for Chrome, Safari and Opera */
#searchResultWrapper::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
#searchResultWrapper {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

// MOBILE =================================================================================================
@media only screen and (max-width: 600px) {
  .mobMenu {
    max-height: 95vh;
    overflow-y: auto;
    overflow-x: visible;
    padding: 0px 0px 40vw;
    image-rendering: -moz-crisp-edges; /* Firefox */
    image-rendering: -o-crisp-edges; /* Opera */
    image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
    box-sizing: border-box;
    position: relative;
    > a:first-of-type {
      margin-top: 3vw;
    }
    > a {
      position: relative;
      width: 100%;
      display: flex;
      justify-content: center;
      font-weight: 500;
      color: #6a6a6a;
    }

    > a > div {
      width: 80%;
      display: flex;
      align-items: center;
      padding: 8px 6vw;
      font-size: 4vw;
    }

    > a.router-link-active > div,
    > a.router-link-active:hover > div {
      background-color: #71b100;
      color: white;
      animation: none;
      font-weight: 500;
      text-align: center;
    }

    > a:hover > div {
      background: #dce4dc;
      transition: 0.1s;
      color: #6a6a6a;
      font-weight: 500;
    }
  }

  .headerHome .mobMenu {
    max-height: 60vh;
  }

  body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 20px #000;
  }

  body::-webkit-scrollbar-thumb {
    background-color: #71b100;
    outline: 1px solid #71b100;
  }

  html {
    padding: 0px;
    margin: 0px;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
  }

  body {
    padding: 0px;
    margin: 0px;
    width: 100%;
    min-height: 100vh;
    overflow: auto;
    overflow-x: hidden;
    line-height: 1.5;
  }

  a {
    text-decoration: none;
    color: inherit;
    display: flex;
  }

  #app {
    font-family: "Roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    font-size: 4vw;
  }

  #header {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    background-color: RGBA(255, 255, 255, 1);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 0px 10px 0px RGBA(0, 0, 0, 0.1);
    z-index: 999;
    border-radius: 0vw;
    transition: all 0.4s;
    transform: translateX(0vw) translateY(0vw);
    transition-timing-function: cubic-bezier(0.62, 0.17, 0.38, 0.84);
    box-sizing: border-box;
    overflow: hidden;
    max-height: 100vh;
  }

  #header > button {
    margin: 3vw 4vw;
  }

  .headerHome {
    background-color: RGBA(255, 255, 255, 0.93) !important;
    width: 93vw !important;
    transform: translateX(3vw) translateY(12vw) !important;
    border-radius: 3vw !important;
    max-height: 90vh !important;
    overflow: hidden;
  }

  #brojtel {
    position: absolute;
    top: -5vw;
    left: 1vw;
    color: #9a9b9c;
    font-size: 3vw;
    font-weight: 300;
  }

  #logo {
    height: 6vw;
    margin: 0px 1vw 0px 0px;
    transition: height 0.4s;
    margin: 3vw 4vw;
  }

  .headerHome #logo {
    height: 6vw;
  }

  #nav {
    display: flex;
    flex-direction: row;
    padding-right: 1vw;
    height: 100%;

    a {
      height: 100%;
      display: flex;
      font-weight: 500;
      color: #6a6a6a;
    }

    a > div {
      height: 100%;
      display: flex;
      align-items: center;
      padding: 0px 1vw;
      font-size: 0.85vw;
    }

    a.router-link-active > div,
    a.router-link-active:hover > div {
      background-color: #71b100;
      color: white;
      animation: none;
      font-weight: 500;
    }

    a:hover > div {
      background: #dce4dc;
      transition: 0.1s;
      color: #6a6a6a;
      font-weight: 500;
    }
  }

  .pomocni {
    background-color: #eef2f4;
  }

  #pageHolder {
    position: relative;
    width: 100vw;
    min-height: 100vh;
    box-sizing: border-box;
    padding: 0vw 0px 0px;
    z-index: 0;
    background-color: #f4f4f4;
    transition: padding 0.4s;
  }

  .phHome {
    padding: 13vw 0px 0px !important;
  }

  #searchInputWrapp input {
    height: 6vw;
    width: 30vw;
    border: 0.01vw solid #ccc;
    padding: 0.2vw 0.5vw;
    font-size: 16px;
  }

  #searchInputWrapp button {
    border: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #71b000;
    padding: 0px 2vw;
  }

  #searchInputWrapp img {
    height: 4vw;
  }

  #menuHolder {
    background-color: #71b100;
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 3vw 2vw;
    box-sizing: border-box;
    transition: all 0.4s;
  }

  .menuItem {
    width: 27vw;
    color: #f0f0f0;
    font-size: 3vw;
    font-weight: 300;
    text-align: center;
    padding: 0.5vw 2vw 1vw;
    text-transform: uppercase;

    img {
      position: relative;
      width: 70%;
    }
  }

  .headerHome #menuHolder {
    padding: 1vw 0vw;
  }

  a.router-link-active > .menuItem,
  a.router-link-active:hover > .menuItem {
    background-color: #61a100;
    color: white;
  }

  .menuItem:hover {
    background-color: #77ba00;
    cursor: pointer;
  }

  .menuItemText {
    margin-top: 0.3vw;
  }

  // TRANSITIONS ---------------------------
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.3s, transform 0.3s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
    transform: translateY(-2vw);
  }

  // ANIMATIONS
  @-webkit-keyframes pulse {
    0% {
      -webkit-transform: scale(0.95);
      opacity: 0.7;
    }
    50% {
      -webkit-transform: scale(1);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(0.95);
      opacity: 0.7;
    }
  }

  @keyframes pulse {
    0% {
      transform: scale(0.95);
      opacity: 0.7;
    }
    50% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(0.95);
      opacity: 0.7;
    }
  }

  .animPulse {
    color: #a00;
    font-weight: 600;
    -webkit-animation: pulse 1s infinite ease-in-out;
    -o-animation: pulse 1s infinite ease-in-out;
    -ms-animation: pulse 1s infinite ease-in-out;
    -moz-animation: pulse 1s infinite ease-in-out;
    animation: pulse 1s infinite ease-in-out;
  }

  .animPulse:hover {
    animation: none;
  }

  #footerHolder {
    position: relative;
    width: 100vw;
    background-color: #0a0a0a;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    color: #666;
    font-size: 4.5vw;
  }

  #footerTre {
    position: relative;
    display: flex;
    display: none;
    flex-direction: column;
    width: 100vw;
    justify-content: space-between;

    img {
      width: 100vw;
      margin: 4vw 0vw 0vw;
      image-rendering: -moz-crisp-edges; /* Firefox */
      image-rendering: -o-crisp-edges; /* Opera */
      image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
      image-rendering: crisp-edges;
      -ms-interpolation-mode: nearest-neighbor;
    }
  }

  #fTop {
    position: relative;
    width: 100%;
    border-bottom: 1px solid #1a1c1b;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    padding: 10vw 0vw;
  }

  #onamaWrapper {
    width: 100vw;
    align-self: flex-start;
    padding: 5vw 5vw;
    box-sizing: border-box;
  }

  #onamaNaslov {
    padding-bottom: 1vw;
    font-weight: 600;
    font-size: 5vw;
    color: #aaa;
  }

  #nagradeWrapper {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 5vw;
    left: -3vw;
    box-sizing: border-box;
  }

  .nagrada {
    width: 32vw;
    height: 32vw;
    img {
      max-height: 100%;
    }
  }

  .servisWrapper {
    width: 100vw;
    align-self: flex-start;
    padding: 5vw;
    box-sizing: border-box;
  }

  .linkoviWrapper {
    width: 100vw;
    align-self: flex-start;
    padding: 5vw;
    box-sizing: border-box;
  }
  .linkoviNaslov {
    padding-bottom: 1vw;
    font-weight: 600;
    font-size: 5vw;
    color: #aaa;
  }
  .linkoviSpisak {
    display: block;

    a {
      display: block;
      padding: 0.2vw 0.3vw;
      transition: background-color 0.3s;
    }

    a:hover {
      background-color: #171817;
      transition: background-color 0s;
    }
  }

  #rvWrapper {
    position: relative;
    bottom: 3.2vw;
    font-size: 6vw;
    color: rgb(122, 221, 65);
    font-style: italic;
    font-weight: 700;
    margin: 10vw;
    text-align: center;
  }

  #katalogWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0vw;

    img {
      width: 60vw;
      height: 84vw;
    }
  }

  #katalogNatpis {
    position: absolute;
    background-color: #900;
    padding: 0.2vw 1vw;
    color: #fff;
    font-size: 4vw;
    font-weight: 600;
    transition: font-size 0.3s, padding 0.3s;
    pointer-events: none;
  }

  #fBottom {
    position: relative;
    width: 100%;
    justify-content: space-between;
    padding-bottom: 5vw;
    text-align: center;
  }

  .felem {
    width: 100vw;
    padding: 5vw 5vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    img {
      height: 16vw;
      opacity: 0.5;
      transition: opacity 0.3s;
      margin: 8vw;
      box-sizing: border-box;
    }

    img:hover {
      opacity: 1;
      transition: opacity 0s;
    }
  }
  #searchResultWrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: baseline;
    box-sizing: border-box;
    flex-wrap: wrap;
    justify-content: space-around;
    max-height: 95vh;
    overflow-y: auto;
    overflow-x: visible;
    padding: 3vw 3vw 60vw;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
    box-sizing: border-box;
    position: relative;
    -ms-scroll-chaining: none;
    overscroll-behavior: contain;
    border-bottom: 2px solid #71b000;
  }

  #searchResultWrapper:first-of-type {
    margin: 15vw 0vw 1vw 0vw;
  }
  .item {
    position: relative;
    width: 100%;
    margin: 4vw 0vw;
    background-color: #fff;
    transition: transform 0.3s;
    padding-bottom: 0.6vw;
    box-sizing: border-box;
    a {
      display: flex;
      flex-direction: column;
      img {
        position: relative;
        width: 100%;
      }
      .naziv {
        padding: 0vw 3vw;
        font-size: 4vw;
        color: #444;
        font-weight: 200;
        margin: 1vw 0vw;
        span {
          font-size: 5vw;
          font-weight: 500;
        }
      }
      .cijena {
        color: #f00;
        font-size: 8vw;
        padding: 0vw 3vw;
        span {
          font-size: 0.8em;
        }
      }
      .popCijena {
        display: flex;
        padding: 0vw 3vw;
        font-size: 4vw;
        div {
          margin: 0px 3vw 0px 0px;
          text-decoration: line-through;
        }
      }

      .popust {
        width: 13vw;
        height: 17vw;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        font-family: "Roboto Condensed", sans-serif;
        font-weight: 700;
        font-size: 7vw;
        padding: 7vw 0vw 0vw;
        box-sizing: border-box;
        letter-spacing: -0.1vw;
        color: #e00;
        margin: 1vw 0vw 0vw 1vw;
      }

      .popust span {
        font-size: 0.5em;
      }

      .popdod {
        background-image: url("/images/popusti/dodatni2.jpg");
        background-size: cover;
      }

      .popdod2 {
        background-image: url("/images/popusti/akcija2.jpg");
        background-size: cover;
      }

      .popsniz {
        background-image: url("/images/popusti/snizenje1.jpg");
        background-size: cover;
      }
      
      .popsniz2 {
        background-image: url("/images/popusti/eksponat.png");
        background-size: cover;
      }

      .popfinalna {
        background-image: url("/images/popusti/finalna.jpg");
        background-size: cover;
      }
      .popeksponat {
        background-image: url("/images/popusti/eksponat.jpg");
        background-size: cover;
        color: #fff !important;
      }
      .popnovo {
        background-image: url("/images/popusti/novo.jpg");
        background-size: cover;
      }

      .popNat {
        font-size: 0.5vw;
        text-align: center;
        line-height: 1.3;
        top: 0px;
        color: #555;
      }
    }
  }
  .popustWrapper {
    width: 27vw;
    height: 16vw;
    position: absolute;
    right: 0.5vw;
    bottom: 1.3vw;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  .koko3 {
    width: 100%;
    .popustWrapper {
      width: 50vw;
      height: 16vw;
      position: absolute;
      right: 1.5vw;
      bottom: 3.6vw;
      display: flex;
      flex-direction: row-reverse;
      justify-content: right;
    }
  }

  .koko4 {
    width: 100%;
    .popustWrapper {
      width: 50vw;
      height: 16vw;
      position: absolute;
      right: 1.5vw;
      bottom: 3.6vw;
      display: flex;
      flex-direction: row-reverse;
      justify-content: right;
    }
  }

  .koko5 {
    width: 100%;
    .popustWrapper {
      width: 50vw;
      height: 16vw;
      position: absolute;
      right: 1.5vw;
      bottom: 3.6vw;
      display: flex;
      flex-direction: row-reverse;
      justify-content: right;
    }
  }

  .koko6 {
    width: 46%;
    .naziv {
      span {
        display: block;
      }
    }
    img {
      padding: 1vw;
      box-sizing: border-box;
    }
    .popustWrapper {
      width: 27vw;
      height: 16vw;
      position: absolute;
      right: -13vw;
      bottom: 1.3vw;
      display: flex;
      flex-direction: column-reverse;
      justify-content: space-between;
    }
    .popCijena {
      flex-direction: column;
    }
  }

  .koko7 {
    width: 46%;
    .naziv {
      span {
        display: block;
      }
    }
    img {
      padding: 1vw;
      box-sizing: border-box;
    }
    .popustWrapper {
      width: 27vw;
      height: 16vw;
      position: absolute;
      right: -13vw;
      bottom: 1.3vw;
      display: flex;
      flex-direction: column-reverse;
      justify-content: bottom;
    }
    .popCijena {
      flex-direction: column;
    }
  }

  .srchBtn {
    width: 28px;
    height: 26px;
    color: #fff;
  }

  #floaterWrapper {
    position: fixed;
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    background-color: RGBA(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999999999999;
  }

  #floaterHolder {
    width: 600px;
    position: relative;
    max-width: 70vw;
    border: 5px solid RGBA(255, 255, 255, 0.2);
    border-radius: 16px;
  }

  #floaterHolder img {
    width: 100%;
    height: 100%;
  }

  #floaterX {
    position: absolute;
    border-radius: 100px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #c00;
    color: #fff;
    top: -30px;
    right: -30px;
    font-size: 15px;
    font-weight: 600;
    pointer-events: none;
  }

  #fixTel {
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100vw;
    box-sizing: border-box;
    background-color: RGBA(60, 100, 0, 0.9);
    padding: 3vw 5vw;
    z-index: 999;
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    font-variant: small-caps;
  }
}

// DESKTOP =================================================================================================

@media only screen and (min-width: 601px) {
  $header-height: 3vw;

  #fixTel {
    display: none;
  }
  .srchBtn {
    width: 2.4vw;
    height: 2.05vw;
    color: #fff;
  }

  body::-webkit-scrollbar {
    width: 0.5vw;
  }

  body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 20px #000;
  }

  body::-webkit-scrollbar-thumb {
    background-color: #71b100;
    outline: 1px solid #71b100;
  }

  html {
    padding: 0px;
    margin: 0px;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
  }

  body {
    padding: 0px;
    margin: 0px;
    width: 100%;
    min-height: 100vh;
    overflow: auto;
    overflow-x: hidden;
    line-height: 1.5;
  }

  a {
    text-decoration: none;
    color: inherit;
    display: flex;
  }

  #app {
    font-family: "Roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    font-size: 0.9vw;
  }

  #header {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 3vw;
    background-color: RGBA(255, 255, 255, 1);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 0px 10px 0px RGBA(0, 0, 0, 0.1);
    z-index: 999;
    border-radius: 0vw;
    transition: all 0.4s;
    transform: translateX(0vw) translateY(0vw);
    transition-timing-function: cubic-bezier(0.62, 0.17, 0.38, 0.84);
    flex-wrap: wrap;
    overflow-y: visible;
  }

  .headerHome {
    background-color: RGBA(255, 255, 255, 0.93) !important;
    width: 93vw !important;
    height: 4vw !important;
    transform: translateX(3vw) translateY(6vw) !important;
    border-radius: 1vw !important;
  }

  #brojtel {
    position: absolute;
    top: -3vw;
    left: 1vw;
    color: #9a9b9c;
    font-size: 1.2vw;
    font-weight: 300;
  }

  #logo {
    height: 2vw;
    margin: 0px 1vw;
    transition: height 0.4s;
  }

  .headerHome #logo {
    height: 2.5vw;
  }

  #searchInputWrapp input {
    height: 1.5vw;
    width: 16vw;
    border: 0.01vw solid #ccc;
    padding: 0.2vw 0.5vw;
  }

  #searchInputWrapp button {
    border: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #71b000;
    padding: 0px 0.6vw;
    right: 1vw;
    position: relative;
  }

  #searchInputWrapp img {
    height: 1.2vw;
  }

  #nav {
    display: flex;
    flex-direction: row;
    padding-right: 1vw;
    height: 100%;

    a {
      height: 100%;
      display: flex;
      font-weight: 500;
      color: #6a6a6a;
    }

    a > div {
      height: 100%;
      display: flex;
      align-items: center;
      padding: 0px 1vw;
      font-size: 0.85vw;
    }

    a.router-link-active > div,
    a.router-link-active:hover > div {
      background-color: #71b100;
      color: white;
      animation: none;
      font-weight: 500;
    }

    a:hover > div {
      background: #dce4dc;
      transition: 0.1s;
      color: #6a6a6a;
      font-weight: 500;
    }
  }

  .pomocni {
    background-color: #eef2f4;
  }

  #pageHolder {
    position: relative;
    width: 100vw;
    min-height: 100vh;
    box-sizing: border-box;
    padding: 0vw 0px 0px;
    z-index: 0;
    background-color: #f4f4f4;
    transition: padding 0.4s;
  }

  .phHome {
    padding: 3vw 0px 0px !important;
  }

  #menuWrapper {
    box-sizing: border-box;
    width: 100%;
    background-color: #71b100;
    position: absolute;
    z-index: 998;
    padding: 1vw;
    bottom: 0px;
    transform: translateY(100%);
    image-rendering: -moz-crisp-edges; /* Firefox */
    image-rendering: -o-crisp-edges; /* Opera */
    image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
  }

  .headerHome > #menuWrapper {
    border-radius: 1vw !important;
  }

  #menuHolder {
    background-color: #71b100;
    width: 55vw;
    margin: 0px auto;
    display: flex;
    flex-wrap: wrap;
  }

  .menuItem {
    width: 8vw;
    color: #f0f0f0;
    font-size: 0.8vw;
    font-weight: 300;
    text-align: center;
    padding: 0.5vw 0.5vw 1vw;
    text-transform: uppercase;

    img {
      position: relative;
      width: 80%;
    }
  }

  a.router-link-active > .menuItem,
  a.router-link-active:hover > .menuItem {
    background-color: #61a100;
    color: white;
  }

  .menuItem:hover {
    background-color: #77ba00;
    cursor: pointer;
  }

  .menuItemText {
    margin-top: 0.3vw;
  }

  // TRANSITIONS ---------------------------
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.3s, transform 0.3s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
    transform: translateY(-2vw);
  }

  // ANIMATIONS
  @-webkit-keyframes pulse {
    0% {
      -webkit-transform: scale(0.95);
      opacity: 0.7;
    }
    50% {
      -webkit-transform: scale(1);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(0.95);
      opacity: 0.7;
    }
  }

  @keyframes pulse {
    0% {
      transform: scale(0.95);
      opacity: 0.7;
    }
    50% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(0.95);
      opacity: 0.7;
    }
  }

  .animPulse {
    color: #a00;
    font-weight: 600;
    -webkit-animation: pulse 1s infinite ease-in-out;
    -o-animation: pulse 1s infinite ease-in-out;
    -ms-animation: pulse 1s infinite ease-in-out;
    -moz-animation: pulse 1s infinite ease-in-out;
    animation: pulse 1s infinite ease-in-out;
  }

  .animPulse:hover {
    animation: none;
  }

  #footerHolder {
    position: relative;
    width: 100vw;
    background-color: #0a0a0a;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    color: #666;
    font-size: 0.9vw;
  }

  #footerTre {
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100vw;
    justify-content: space-between;

    img {
      width: 33.5vw;
      image-rendering: -moz-crisp-edges; /* Firefox */
      image-rendering: -o-crisp-edges; /* Opera */
      image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
      image-rendering: crisp-edges;
      -ms-interpolation-mode: nearest-neighbor;
    }
  }

  #fTop {
    position: relative;
    width: 100%;
    border-bottom: 1px solid #1a1c1b;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  #onamaWrapper {
    width: 18vw;
    align-self: flex-start;
    padding: 2vw;
  }

  #onamaNaslov {
    padding-bottom: 1vw;
    font-weight: 600;
    font-size: 1vw;
    color: #aaa;
  }

  #nagradeWrapper {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 2vw;
    left: -1vw;
  }

  .nagrada {
    width: 7vw;
    height: 7vw;
    img {
      max-height: 100%;
    }
  }

  .servisWrapper {
    width: 13vw;
    align-self: flex-start;
    padding: 2vw;
  }

  .linkoviWrapper {
    width: 28vw;
    align-self: flex-start;
    padding: 2vw;
  }
  .linkoviNaslov {
    padding-bottom: 1vw;
    font-weight: 600;
    font-size: 1vw;
    color: #aaa;
  }
  .linkoviSpisak {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 14vw;

    a {
      display: block;
      padding: 0.2vw 0.3vw;
      transition: background-color 0.3s;
    }

    a:hover {
      background-color: #171817;
      transition: background-color 0s;
    }
  }

  #rvWrapper {
    position: absolute;
    right: 25vw;
    bottom: 3.2vw;
    font-size: 1.3vw;
    color: rgb(122, 221, 65);
    font-style: italic;
    font-weight: 700;
  }

  #katalogWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0vw;
    margin: 2vw 5vw 2vw 0vw;

    img {
      width: 13.5vw;
    }
  }

  #katalogWrapper:hover img {
  }

  #katalogNatpis {
    position: absolute;
    background-color: #900;
    padding: 0.2vw 1vw;
    color: #fff;
    font-size: 0.9vw;
    font-weight: 600;
    transition: font-size 0.3s, padding 0.3s;
    pointer-events: none;
  }

  #katalogWrapper:hover #katalogNatpis {
    font-size: 1vw;
    padding: 0.4vw 1vw;
  }

  #fBottom {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 1vw;
  }

  .felem {
    padding: 2vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    img {
      height: 2.8vw;
      margin-right: 0.7vw;
      opacity: 0.5;
      transition: opacity 0.3s;
    }

    img:hover {
      opacity: 1;
      transition: opacity 0s;
    }
  }

  #searchResultWrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: baseline;
    box-sizing: border-box;
    flex-wrap: wrap;
    justify-content: space-around;
    max-height: 95vh;
    overflow-y: auto;
    overflow-x: visible;
    padding: 1vw 3vw 4vw;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
    box-sizing: border-box;
    position: relative;
    -ms-scroll-chaining: none;
    overscroll-behavior: contain;
    background-color: #fff;
    border-bottom: 2px solid #71b000;
  }

  .headerHome #searchResultWrapper {
    max-height: 60vh !important;
    border-radius: 1vw;
  }

  .item {
    position: relative;
    width: 22%;
    margin: 1.4vw;
    background-color: #fff;
    transition: transform 0.3s, box-shadow 0.3s;
    padding-bottom: 0.6vw;
    box-shadow: 0px 0px 0px 0px RGBA(0, 0, 0, 0);
    a {
      display: flex;
      flex-direction: column;
      img {
        position: relative;
        width: 100%;
      }
      .naziv {
        padding: 0vw 0.6vw;
        font-size: 0.9vw;
        color: #444;
        font-weight: 200;
        margin: 0.3vw 0vw;
        span {
          font-size: 1.2vw;
          font-weight: 500;
        }
      }
      .cijena {
        color: #f00;
        font-size: 1.7vw;
        padding: 0vw 0.6vw;
        span {
          font-size: 0.8em;
        }
      }
      .popCijena {
        display: flex;
        padding: 0vw 0.6vw;
        font-size: 1vw;
        div {
          margin: 0px 0.2vw;
          text-decoration: line-through;
        }
      }

      .popust {
        width: 3.5vw;
        height: 4.5vw;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        font-family: "Roboto Condensed", sans-serif;
        font-weight: 700;
        font-size: 2vw;
        padding: 1.7vw 0vw 0vw;
        box-sizing: border-box;
        letter-spacing: -0.1vw;
        color: #e00;
        margin: 0.4vw 0vw 0vw 0.4vw;
      }

      .popust span {
        font-size: 1vw;
      }

      .popdod {
        background-image: url("/images/popusti/dodatni1.jpg");
        background-size: cover;
      }
      .popdod2 {
        background-image: url("/images/popusti/akcija2.jpg");
        background-size: cover;
      }

      .popsniz {
        background-image: url("/images/popusti/snizenje1.jpg");
        background-size: cover;
      }
      
      .popsniz2 {
        background-image: url("/images/popusti/eksponat.png");
        background-size: cover;
      }
      
      .popfinalna {
        background-image: url("/images/popusti/finalna.jpg");
        background-size: cover;
      }
      .popeksponat {
        background-image: url("/images/popusti/eksponat.jpg");
        background-size: cover;
        color: #fff !important;
      }
      .popnovo {
        background-image: url("/images/popusti/novo.jpg");
        background-size: cover;
      }

      .popNat {
        font-size: 0.5vw;
        text-align: center;
        line-height: 1.3;
        top: 0px;
        color: #555;
      }
    }
  }

  .koko3 {
    width: 30%;
    .popustWrapper {
      width: 15vw;
      height: 3.5vw;
      position: absolute;
      right: 0.5vw;
      bottom: 1.8vw;
      display: flex;
      flex-direction: row-reverse;
      justify-content: right;
    }
  }

  .koko4 {
    width: 22%;
    .popustWrapper {
      width: 15vw;
      height: 3.5vw;
      position: absolute;
      right: 0.5vw;
      bottom: 1.8vw;
      display: flex;
      flex-direction: row-reverse;
      justify-content: right;
    }
  }

  .koko5 {
    width: 17%;
    .popustWrapper {
      width: 15vw;
      height: 3.5vw;
      position: absolute;
      right: 0.5vw;
      bottom: 1.8vw;
      display: flex;
      flex-direction: row-reverse;
      justify-content: right;
    }
  }

  .koko6 {
    width: 13.5%;
    .naziv {
      span {
        display: block;
      }
    }
    img {
      padding: 1vw;
      box-sizing: border-box;
    }
    .popustWrapper {
      width: 3.7vw;
      height: 4vw;
      position: absolute;
      right: 0.3vw;
      bottom: 0.9vw;
      display: flex;
      flex-direction: column-reverse;
      justify-content: flex-end;
    }
  }

  .koko7 {
    width: 10.5%;
    .naziv {
      span {
        display: block;
      }
    }
    img {
      padding: 1vw;
      box-sizing: border-box;
    }
    .popustWrapper {
      width: 3.7vw;
      height: 4vw;
      position: absolute;
      right: 0.6vw;
      bottom: 0.9vw;
      display: flex;
      flex-direction: column-reverse;
      justify-content: flex-end;
    }
  }

  .item:hover {
    background-color: #fefefe;
    transform: scale(1.1);
    box-shadow: 0px 0px 15px 0px RGBA(0, 0, 0, 0.2);
  }

  #floaterWrapper {
    position: fixed;
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    background-color: RGBA(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999999999999;
  }

  #floaterHolder {
    width: 600px;
    position: relative;
    max-width: 25vw;
    border: 5px solid RGBA(255, 255, 255, 0.2);
    border-radius: 16px;
  }

  #floaterHolder img {
    width: 100%;
    height: 100%;
  }

  #floaterX {
    position: absolute;
    border-radius: 100px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #c00;
    color: #fff;
    top: -30px;
    right: -30px;
    font-size: 15px;
    font-weight: 600;
    pointer-events: none;
  }
}
</style>
